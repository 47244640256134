<template>
    <div>
        <a-drawer
            :zIndex="1000"
            :width="720"
            @close="onClose"
            :visible="columnsDrawer.visible"
            :wrapStyle="{overflow: 'auto',}"
            >
            <h3 class="text-dark">Select the columns you want to display in this list</h3>
            <h5 class="text-dark mt-5">Visible Columns</h5>
            <draggable :move="checkMove" :list="columns" @end="endMove" :delay-on-touch-only="true" :delay="100">
                <div v-for="(column, columnI) in columns" :key="column.key+columnI" :value="column.key+columnI">
                    <div v-if="column.title != '' || column.key === 'leadScore'" class="dF aC py-2 column-display">
                        <i style="cursor:grab" :style="column.key == 'name' ? 'color:#D0C9D6':''" class="fe fe-align-justify mr-2" />
                        <a-checkbox @change="(e) => checkField(e, column.key)" :checked="columnKeys.includes(column.key)" :disabled="column.key == 'name' ? true:false" class="mr-3" />
						<a-tooltip v-if="column.key === 'leadScore'" overlayClassName="change-tooltip-color">
							<template slot="title">
								This helps sales determine the amount of interactions the lead has had in regards to form submission, email interaction.
	                        </template>
							Interaction Activity
	                        <a-icon type="question-circle" style="font-size: 14px" />
	                    </a-tooltip>
						<div v-else>{{column.title}}</div>
                    </div>
                </div>
            </draggable>
            <div v-if="showAlert" class="dF aC mt-3 py-2 px-3 jC" style="background-color:#ECE9F1; color:#3F3356; border-radius:4px">
                <i class="fe fe-alert-triangle mr-2" />
                <div>You have added maximum number of columns. Remove any other column to add other items.</div>
            </div>
            <h5 class="text-dark mt-5">Hidden Fields</h5>
            <div v-for="column in allColumns" :key="column.key" :value="column.key">
                <div v-if="!columnKeys.includes(column.key)" class="dF aC py-2 column-display">
                    <a-checkbox @change="(e) => checkField(e, column.key)" :checked="columnKeys.includes(column.key)" :disabled="column.key == 'name' ? true:false" class="mr-3" />
					<a-tooltip v-if="column.tooltip" overlayClassName="change-tooltip-color">
						<template slot="title">
							{{ column.tooltip }}
                        </template>
						{{column.title}}
                        <a-icon type="question-circle" style="font-size: 14px" />
                    </a-tooltip>
                    <div v-else>{{column.title}}</div>
                </div>
            </div>
        </a-drawer>
    </div>
</template>

<script>
import draggable from 'vuedraggable'
export default {
    components:{
        draggable
    },
    props:{
        columns:{
            type:Array,
            default: () => []
        }
    },
    data() {
        return {
            showAlert:false,
            allColumns:[
                {title:'Name', key:'name', fixed:true},
                {title:'Email Address', key:'email'},
                {title:'Tags', key:'tags'},
                {title:'Sales Owner', key:'owners'},
                {title:'Company', key:'company'},
                {title:'Lead Status', key:'status'},
                {title:'Lead Source', key:'source'},
                {title:'Interaction Activity', key:'leadScore', tooltip:'This helps sales determine the amount of interactions the lead has had in regards to form submission, email interaction.'},
                {title:'Last Updated Date', key:'lastUpdateDate'},
                {title:'Inactive Days', key:'inactiveDays'},
                {title:'Job Title', key:'jobTitle'},
                {title:'Phone', key:'phone'},
                {title:'Street', key:'address'},
                {title:'City', key:'city'},
                {title:'Province / State', key:'region'},
                {title:'Country', key:'country'},
                {title:'Postal / Zip', key:'postal'},
				{title:'Opt-in Status', key:'unsub'},
                {title:'Created At', key:'createdAt'},
				{title:'Realtor?', key:'isAgent'},
				{title:'SMS Consent?', key:'smsConsent'}
            ],
            fixed:false
        }
    },
    computed:{
        columnsDrawer() {
            return this.$store.state.contacts.columnsDrawer
        },
        columnKeys() {
            let keys = this.columns.map(x => x = x.key)
            return keys
        }
    },
    methods:{
        checkField(e, key) {
            console.log('run', this.columnKeys.includes(key), this.columns.length, this.showAlert)
            if (!this.columnKeys.includes(key)) {
                console.log('run this')
                if (!this.showAlert && this.columns.length >= 11) {
                    console.log('timeout')
                    this.showAlert = true
                    setTimeout(() => {
                        console.log('TIMEOUT RAN 3 SECONDS')
                        this.showAlert = false
                    }, 3000)

                }
                if (this.columns.length < 11) {
                    if (e.target.checked) {
                        console.log('adding')
                        let keys = this.columnKeys
                        keys.splice(this.columnKeys.length - 1,1)
                        keys.push(key)
                        keys.push('actions')
                        this.$emit('updateColumns', keys)
                    }
                }
            } else if (this.columnKeys.includes(key)) {
                if (!e.target.checked) {
                    console.log('remove')
                    let keys = this.columnKeys
                    let index = this.columnKeys.findIndex(x => x == key)
                    if(index != -1) {
                        keys.splice(index,1)
                        this.$emit('updateColumns', keys)
                    }

                }
            }
        },
        onClose() {
            this.$store.commit('CLOSE_COLUMNS_DRAWER')
        },
        checkMove(e){
            this.fixed = this.isDraggable(e.draggedContext)
            return this.isDraggable(e.draggedContext)

        },
        isDraggable(context) {
            const { index, futureIndex } = context
            return !(this.allColumns[index].fixed || this.allColumns[futureIndex].fixed);
        },
        endMove(e){
            if (this.fixed) {
                console.log('e',this.allColumns, this.columnKeys)
                this.$emit('updateColumns', this.columnKeys)
            }
        }
    }
}
</script>

<style scoped>
.column-display{
    margin-left:-24px;
    margin-right:-24px;
    padding:0 24px;
}
.column-display:hover{
    background-color:#FAF9F9;
}
</style>
